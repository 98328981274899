<template lang="pug">
.main-wrapper
    Dialog(header='Remover Tipo' :visible.sync='dialog.apagar' :modal='true')
        p Deseja remover o tipo?
        .ta-center.my-4
            Button.p-button-danger(label='Remover' @click='remove()')

    Dialog(header='Adicionar Tipo' :visible.sync='dialog.adicionar' :modal='true')
        form(@submit.prevent='adicionar()')
            InputText(v-model="novoTipo" required)
            .ta-center.my-4
                Button(label='Adicionar' type="submit")

    header.main-wrapper-header
        .p-grid.p-align-center
            .p-col-3
                Button.p-button-secondary.btn-back(icon='jam jam-chevrons-left' @click='$router.go(-1)')
            .p-col-9.ta-right
                h1.text-header.text-secondary MedClub / Anexos / <b>Tipos</b>

    .ta-right.my-2
            Button(label='Adicionar' icon="jam jam-plus" @click="dialog.adicionar = true")

    ProgressBar(v-if="waiting || waitingApagar" mode="indeterminate")
    div(v-else-if='list.length == 0')
        p.ta-center.text-secondary(style='margin-top: 40px;') Nenhum registro encontrado.
    div(v-else)
        Panel.datatable(header='Tipos de Anexos')
            DataTable(:value="list")
                Column(headerStyle='width: 45%;' field='name' header='Tipo')

                Column(headerStyle='width: 10%; text-align: center')
                    template(#header) Ações
                    template(#body='props')
                        .ta-center
                            Button.p-button-raised.p-button-rounded.p-button-danger(
                                v-tooltip.top="'Apagar'"
                                icon="jam jam-minus-circle"
                                @click="dialogApagar(props.data.id)"
                            )
</template>

<style lang="scss" scoped>
.dialogApagar {
    .p-progress-spinner {
        width: 29px;
        height: auto;
    }
}
</style>

<script>
import Noticias from '../../middleware/controllers/Noticias'
import ProgressBar from "primevue/progressbar";
import Dialog from 'primevue/dialog'
import DataView from 'primevue/dataview'
import Panel from 'primevue/panel'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import Button from 'primevue/button'
import Tooltip from 'primevue/tooltip'
import InputText from 'primevue/inputtext'

export default {
    name: "PerguntasFrequentes",
    components: { ProgressBar, Dialog, DataView, Panel, DataTable, Column, Button, Tooltip, InputText },
    directives: { tooltip: Tooltip },
    data() {
        return {
            waiting: false,
            waitingApagar: false,
            dialog: {
                apagar: false,
                adicionar: false
            },
            idASerApagado: '',
            list: [],
            tokenAPI: '',
            categorias: [],
            novoTipo: ''
        };
    },
    created() {
        //Login na nova API
        Noticias.login().then(result => {
            this.tokenAPI = result;
            this.getList()
        })
    },
    methods: {
        getList() {
            this.waiting = true
            Noticias.getAttachmentTypes(this.tokenAPI)
            .then(response => {
                if(! response) this.list = []
                else this.list = response.rows
                this.waiting = false
            })
        },
        dialogApagar(id) {
            this.idASerApagado = id
            this.dialog.apagar = true
        } ,
        remove() {
            this.waiting = true
            Noticias.removeAttachmentType(this.idASerApagado, this.tokenAPI).then(response => {
                this.dialog.apagar = false
                if(! response)
                    this.$toast.error('Não foi possível remover o tipo.', { duration: 3000 })
                else {
                    this.idASerApagado = ''
                    this.getList()
                }
                this.waiting = false
            })
        },
        adicionar() {
            this.waiting = true
            Noticias.saveAttachmentType({ name: this.novoTipo }, this.tokenAPI).then(response => {
                this.dialog.adicionar = false
                if(! response)
                    this.$toast.error('Não foi possível adicionar o tipo.', { duration: 3000 })
                else {
                    this.novoTipo = ''
                    this.getList()
                }
                this.waiting = false
            })
        }
    },

};
</script>
